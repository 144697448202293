import { AppProvider } from "./providers/app";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.scss";
import { ApplicantGoalTrackingOptions, setConfigs, setTheme } from "./config";
import { Connection, Finapps } from "./features/open-banking";

 // eslint-disable-next-line
 import bootstrap from "!!raw-loader!bootstrap/dist/css/bootstrap.min.css";

// eslint-disable-next-line
import bootstrapIcons from "!!raw-loader!!bootstrap-icons/font/bootstrap-icons.min.css";

 // eslint-disable-next-line
 import indexCss from "!!raw-loader!./index.css";

import { StyleSheetManager } from "styled-components";
import { ApplicantLoanAccountLink } from "./features/open-banking/components/ApplicantLoanLink";
import { ApplicantSavingsAccountLink } from "./features/open-banking/components/ApplicantSavingsLink";
import { GetGoalTrackingSettings } from "./features/open-banking/components/GetGoalTrackingSettings";

// export function App({ options, theme }: any) {
//   setTheme(theme);
//   setConfigs(options as ApplicantGoalTrackingOptions);
//   return (
//     <AppProvider>
//       <Connection />
//     </AppProvider>
//   );
// }

// export function FinAppsApp({ options, theme }: any) {
//   setTheme(theme);
//   setConfigs(options as ApplicantGoalTrackingOptions);

//   return (
//     <AppProvider>
//       <style>{bootstrap}</style>
//       <style>{bootstrapIcons}</style>
//       <Finapps />
//     </AppProvider>
//   );
// }

// export default App;


export function LoanLinkingApp({ options, theme }: any) {
  setTheme(theme);
  setConfigs(options as ApplicantGoalTrackingOptions);

  var head = document.head || document.getElementsByTagName('head')[0], style = document.createElement('style');
  head.appendChild(style);
  style.type = 'text/css';
  style.appendChild(document.createTextNode(indexCss));

  var shadowRoot = document.getElementsByTagName('applicant-loan-link')[0].shadowRoot;
  var elem = shadowRoot?.appendChild(document.createElement('div'));

  return (
    <StyleSheetManager target={elem}>
      <style>{bootstrap}</style>
      <style>{bootstrapIcons}</style>
      <AppProvider>
        <ApplicantLoanAccountLink />
      </AppProvider>
    </StyleSheetManager>
  );
}

export function SavingsLinkingApp({ options, theme }: any) {
  setTheme(theme);
  setConfigs(options as ApplicantGoalTrackingOptions);

  var head = document.head || document.getElementsByTagName('head')[0], style = document.createElement('style');
  head.appendChild(style);
  style.type = 'text/css';
  style.appendChild(document.createTextNode(indexCss));

  var shadowRoot = document.getElementsByTagName('applicant-savings-link')[0].shadowRoot;
  var elem = shadowRoot?.appendChild(document.createElement('div'));

  return (
    <StyleSheetManager target={elem}>
      <style>{bootstrap}</style>
      <style>{bootstrapIcons}</style>
      <AppProvider>
        <ApplicantSavingsAccountLink />
      </AppProvider>
    </StyleSheetManager>
  );
}

export function GetGoalTrackSettingsApp({ options }: any) {
  setConfigs(options as ApplicantGoalTrackingOptions);
  return (
    <AppProvider>
      <GetGoalTrackingSettings />
    </AppProvider>
  );
}

